import React from "react";
import { RiCloseFill } from "react-icons/ri";
import { TbConfetti } from "react-icons/tb";
import { VscError } from "react-icons/vsc";
import PreviewPdf from "./PreviewPdf";
import termsFile from "./assets/doc/t&c.pdf";
import { Link } from "react-router-dom";

const Terms = ({ closeReadTerms }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.2)",
        zIndex: 10000000000,
      }}
    >
      <div className="page">
        {/* <div
          onClick={closeReadTerms}
          style={{
            position: "absolute",
            top: 55,
            left: 30,
            padding: "12px",
            // paddingTop: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
            borderRadius: "24px",
            cursor: "pointer",
            zIndex: 10000000000,
            textShadow: "8px 8px 10px #000000",
          }}
          className="tbutton"
          // className=" absolute -top-4 -right-2 flex  p-3  justify-center items-center bg-white rounded-full shadow-lg cursor-pointer hover:scale-95"
        >
          <RiCloseFill
            className=" text-lg "
            style={{ fontSize: "24px", color: "white" }}
          />
        </div> */}

        {/* <div
        // className=" flex w-full  justify-between items-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 26,
          left: 0,
          right: 0,
          width: "100%",
          zIndex: 1000000000
        }}
      >
        <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <p style={{ fontSize: "24px", textAlign:"center", width: "100%", margin: "auto" }}>Terms and Conditions</p>
        </div>
       
      </div> */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "6px",
            paddingBottom: "16px",
            width: "100vw",
            height: "100vh",
          }}
          className="flex justify-center items-center "
        >
          {/* <PreviewPdf file={"/assets/doc/t&c.pdf"} /> */}
          {/* <a href="" download={"studentkycterms&conditions"}>Download Terms and Condition</a> */}
          <p className="!text-[25px]" style={{fontSize: "25px", textAlign: "center"}}>CalBank Student KYC</p>
          <Link onClick={closeReadTerms} to="/assets/doc/t&c.pdf" target="_blank" download={'CalbankStudentKycT&C.pdf'} style={{fontSize: "20px", textAlign: "center"}}>
            Download Terms and Conditions
          </Link>
        </div>

        {/* <div className=" p-10 py-6 ">
          {type === "success" ? (
              <p className="text-center text-xl text-green-800">{message}</p>
          ):(
              <p className="text-center text-xl text-red-700">{message}</p>
          )}
            
          </div> */}
      </div>
    </div>
  );
};

export default Terms;
