//asset
export const VERIFY_GHANA_CARD_REQUEST = 'VERIFY_GHANA_CARD_REQUEST';
export const VERIFY_GHANA_CARD_SUCCESS = 'VERIFY_GHANA_CARD_SUCCESS';
export const VERIFY_GHANA_CARD_FAIL = 'VERIFY_GHANA_CARD_FAIL';
export const VERIFY_GHANA_CARD_RESET = 'VERIFY_GHANA_CARD_RESET';

export const VERIFY_ACCOUNT_REQUEST = 'VERIFY_ACCOUNT_REQUEST';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_FAIL = 'VERIFY_ACCOUNT_FAIL';
export const VERIFY_ACCOUNT_RESET = 'VERIFY_ACCOUNT_RESET';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';
export const VERIFY_OTP_RESET = 'VERIFY_OTP_RESET';

