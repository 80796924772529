import React from 'react'
import {
    BlockTitle,
  } from "../../../../components/Component";
  import Logo from "../../../../assets/calbanklogo.png"
function LogoCard() {
  return (
    <div style={{position: "relative", width: "100vw", height: "20vh"}}>
     <img src={Logo} alt="" style={{position: "absolute", top:"-75px", right: "-20px", width:"20rem", height:"14rem"}}  />
    </div>
    // // <div style={{ background: "rgba(255,255,255, 0.2)", backdropFilter: "blur(5px)", display: "relative" , width: "100%"}}>
    // {/* // <BlockTitle style={{display: "absolute", right: 0, top: 0}} className="fw-normal" tag="h2"> */}
    //   {/* Nice, Abu Bin Ishtiyak ! */}
    //  {/* <img src={Logo} alt="" style={{display: "absolute", top:0, right: 0}} width={"100px"} height={"60px"} /> */}
    // {/* // </BlockTitle> */}
    // {/* </div> */}
  )
}

export default LogoCard